<template>
  <div>
    <section class="section-navigation">
      <v-row class="pa-8">
        <v-img
          src="../assets/Cawthron_PrimaryLogo_white.svg"
          max-height="60"
          max-width="200"
          contain
        >
        </v-img>
      </v-row>
    </section>
    <section class="section-hero">
      <v-layout justify-center align-center>
        <div style="margin: 0 0 -125px 0;">
        <v-row class="row-hero-into">
          <v-col align="center" justify="center">
            <div style="margin-bottom:  -100px;">
            <h1 class="white--text" style="margin: 0 -100px;">Freshwater Biodiversity</h1>
            <h1 class="white--text" style="">Monitoring Guide</h1>
          </div>
            <div style="margin-top: 230px">
            <v-btn
              class="my-10"
              @click="$vuetify.goTo('#tool', scrollOptions)"
              x-large
            >
              Get started
            </v-btn>
          </div>
          </v-col>
        </v-row>
      </div>
      </v-layout>
    </section>
    <section>
      <v-layout justify-center align-center>
        <v-row>
          <v-col align="center">
            <h4 style="color: #004f52;">
              A decision support tool that helps you measure how effective your
              biodiversity restoration actions are.
            </h4>
          </v-col>
        </v-row>
      </v-layout>
    </section>

    <section class="section-tool-overview">
      <v-card>
        <v-img
          :aspect-ratio="isMobile ? '1.1' : '3.4'"
          :src="isMobile ? images[0].toolOverviewSm : images[0].toolOverviewLg"
        >
          <v-row class="fill-height" align="center" justify="center">
            <v-col align="center" justify="center">
              <h2 class="white--text">Tool Overview</h2>
            </v-col>
          </v-row>
        </v-img>
      </v-card>
    </section>
    <section class="section-marine-bg">
      <v-layout justify-center align-center>
        <v-row>
          <v-col justify="center">
            <h4 class="white--text" style="margin-bottom: 50px;text-align: left;">
              The  Freshwater Biodiversity Monitoring Guide provides a simple decision-making
              pathway.
            </h4>
            <p class="white--text">
              By identify what actions and pressures to monitor. It provides
              references to existing and standardised methods consistent with
              current Department of Conservation monitoring programs.
            </p>
            <p class="white--text">
              This guide lays out a monitoring framework to assess the success of
              freshwater restoration actions. Some actions will show results
              quickly, but restoration actions targeted at achieving long-term
              biodiversity outcomes may take decades for improvements to be
              seen.
            </p>

            <p class="white--text">
              Therefore, this framework links Restoration Actions with desired
              Outcomes and uses Environmental Pressures to monitor incremental
              change to highlight improvements before the Outcomes are realised.
            </p>

            <p class="white--text">
              Using Riparian Fencing as an example, this 
              
              <a href="FWBMT_Riparian_Fencing_Example.pdf" target="_blank"
                  class="white--text">diagram provides an overview </a>of 
                how the elements of the Freshwater Biodiversity Monitoring Guide link together.
            </p>

          <div style="text-align: center;
                      margin-top: 70px;
                      margin-bottom: -25px;
          ">
            <v-btn
              href="jobs-for-nature-biodiversity-monitoring.pdf"
              class="mt-5"
              target="_blank"
            >
              Read Report
            </v-btn>

          </div>
          </v-col>
        </v-row>
      </v-layout>
    </section>
    <section class="section-neutral-cream-bg">
      <v-layout justify-center align-center>
        <v-row>
          <v-col justify="center" align="center">
            <h2 style="margin-bottom: 50px;">How it works</h2>
            <p style = "width:800px; text-align: center;">
              No matter where you are in your restoration journey, this guide can
              help you.  
              Here is an overview of the frame work and its
              terminology to get you started:
            </p>

            <v-img
              class="mt-8 responsive-img"
              style = "max-width: 38%; height:auto; margin-bottom: -66px; "
              src="../assets/images/BMT_Donut.png"
              contain
            >
            </v-img>
          </v-col>
        </v-row>
      </v-layout>
    </section>
    <section class="section-marine-bg section-app-container" id="tool">
      <v-layout justify-center align-center>
        <v-row class="app-container-row">
          <LandingPage />
        </v-row>
      </v-layout>
    </section>
    <section>
      <v-layout justify-center align-center>
        <v-row>
          <v-col align="center" style="margin-left: -40px;">
            <p class="text-h5 black--text">
              This project was made possible with the help of these
              organisations
            </p>
          </v-col>
          <v-row justify="center"
                  align = "center"
                  >
            <v-col
              cols="12"
              sm="4"
              md="4"
              lg="4"
              v-for="logo in logos"
              :key="'l' + logo.img"
              
            >
              <a target="_blank" :href="logo.url">
                <v-img
                  :src="logo.img"
                  :max-height="logo.size"
                  :max-width="logo.size"
                  contain
                />
              </a>
            </v-col>
          </v-row>
        </v-row>
      </v-layout>
    </section>
    <v-footer padless>
      <v-card flat tile dark width="100%" class="section-marine-bg text-center">
        <v-card-text>
          <v-row class="py-14">
            <v-col cols="3" class="mx-auto" >
              <v-img
                src="../assets/Nga_Awa_white.png"
                max-width="200"
                contain
              >
              </v-img>
            </v-col>
            <v-col cols="3" class="mx-auto">

              <div class="text-sm-left ">

                <p class="white--text"> Freshwater Biodiversity Monitoring Guide</p>
                <div>
                    <a class = "footer-links" href="https://www.doc.govt.nz/our-work/freshwater-restoration/nga-awa/" 
                       target="_blank"><strong>Ngā Awa river restoration programme</strong></a>

                  </div>

                <a mailto="freshwaterrestoration@doc.govt.nz"  class="white--text footer-mail"><strong>freshwaterrestoration@doc.govt.nz</strong></a>
                <p class="white--text footer-mail">Copyright © {{ new Date().getFullYear() }} —
                     <strong>Cawthron Institute</strong></p>
              </div>

            </v-col>
          </v-row>
        </v-card-text>
       
      </v-card>
    </v-footer>
  </div>
</template>




<script>
// const path = require('path');

import LandingPage from "../components/LandingPage";

export default {
  name: "Home",

  components: {
    LandingPage,
  },
  data() {
    return {
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      },
      logos: [
        {
          size:250,
          img: require("@/assets/images/logos/doc.png"),
          url: "https://www.doc.govt.nz/",
        },
        {
          size: 250,
          img: require("@/assets/images/logos/cawthron.png"),
          url: "https://www.cawthron.org.nz",
        },
        {
          size: 150,
          img: require("@/assets/Nga_Awa_colour.png"),
          url: "https://www.doc.govt.nz/our-work/freshwater-restoration/nga-awa/",
        },
      ],
      images: [
        {
          toolOverviewLg: require("@/assets/images/tool_overview.webp"),
          toolOverviewSm: require("@/assets/images/tool_overview_sm.jpg"),
        },
      ],

      isMobile: false,
    };
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
      console.log(this.isMobile);
    },
  },
};
</script>


<style scoped>
.v-parallax__image {
  width: 50% !important;
}
</style>