import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/assets/scss/styles.scss'
import { inject } from '@vercel/analytics';

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

// Call the inject function here to add the tracking script
inject();

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')