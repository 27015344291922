<template>
  <div
    :class="item.level"
    :style="'outline: solid;  outline-width:' + borderWidth + ';'"
  >
    <v-card class="actions-outcomes_card" tile elevation="0" :ripple="false">
      <v-img
        :src="
          require('../assets/images/cards-thumbnails/' +
            item.title.replace(/[^A-Z0-9]+/gi, '-').toLowerCase() +
            '.jpg')
        "
        aspect-ratio="1.7"
        cover
        @click="$emit('clicked')"
      ></v-img>

      <v-card-title
        :class="item.level"
        class="justify-center py-2 px-0 white--text"
        @click="$emit('clicked')"
      >
        {{ item.label }}
      </v-card-title>
      <v-expansion-panels flat tile>
        <v-expansion-panel>
          <v-expansion-panel-header
            class="pt-6 pl-0 pb-0 mb-2"
            elevation="0"
            @click="showLongText = !showLongText"
          >
            <v-card-text class="py-0 pr-2 grey--text text--darken-2">
              {{
                showLongText
                  ? item.longText
                  : item.longText.slice(0, 100) + "..."
              }}
            </v-card-text>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-card-actions>
        <v-btn
          text
          class="j4n-button-outcomes"
          @click="$emit('clicked')"
          block
          :ripple="false"
          :class="selected && 'selected'"
        >
          <v-icon right v-if="selected"> mdi-check </v-icon>
          <span v-if="selected">Selected</span>
          <span v-else> Select</span>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="selected" class="pb-1">
            <CheckBoxGroup
              :group="group"
              v-for="group in item.subOutcomes"
              :key="item.level + group.name"
              :triggerReset="triggerReset"
              @selection="handleSelection($event, group)"
            />
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import CheckBoxGroup from "./CheckBoxGroup.vue";

export default {
  props: {
    item: Object,
    selected: Boolean,
  },
  components: {
    CheckBoxGroup,
  },
  watch: {
    selected: {
      handler(newState){
        if (newState==false){
          this.triggerReset = !this.triggerReset
        }
      }
    }

  },
  methods: {
    handleSelection: function(sel, group){
      let levelAware = sel.map(el => 'L'+this.item.id+'_'+el)
      
      this.selectedOutcomeComponents[group.id-1] = levelAware

      let combinedComponents = [ ...this.selectedOutcomeComponents].flat()


      this.$emit('updateOutcomes', combinedComponents)
      console.log(combinedComponents)

      
    }
  },
  name: "OutcomeCard",
  data: () => ({
    show: false,
    triggerReset: false,
    showLongText: false,
    selectedOutcomeComponents: [[],[],[],[]],

  }),
  computed: {
    borderWidth() {
      return this.selected ? "3px" : "0px";
    },
  },
};
</script>
