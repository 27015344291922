export const actions = [{
    icon: "mdi-fence",
    title: "Riparian fencing",
    actionIndicatorIdx: [0],
    pressureIdx: [0, 1, 2, 3, 4],
    longText: `Permanent or temporary fences along the banks of streams and other waterways protect this (riparian) area from damage from 2- and 4-footed or wheeled traffic. Streambanks can stabilise and vegetation can flourish. Water quality will improve as a result.`
    // actionIndicatorIdx: [],
    // pressureIdx: [],
},
{
    icon: "mdi-tree",
    title: "Riparian planting",
    actionIndicatorIdx: [1, 2],
    pressureIdx: [0, 1, 2, 3, 4],
    longText: `Planting natives along protected stream banks increases species diversity, stabilizes soils and shades small streams. The plants help the soil to act as a natural filter for water entering the stream. They also provide habitat and food for native wildlife both beside and in the stream.`

    // actionIndicatorIdx: [],
    // pressureIdx: [],
},
{
    icon: "mdi-earth",
    title: "Sediment trap/interception",
    actionIndicatorIdx: [3],
    pressureIdx: [3, 4],
    longText: `Sediment ponds, basins, or traps can take in, and slow, surface runoff and allow sediment to drop out before it reaches a stream. They may also remove excess nutrients and other pollutants.`

},
{
    icon: "mdi-water",
    title: "Wetland restoration",
    actionIndicatorIdx: [4],
    pressureIdx: [0, 4],
    longText: `Swamps, bogs and marshes support unique plants and animals. Restoring these areas increases biodiversity and provides many ecosystem services, like nutrient removal, and flood mitigation.`

    // actionIndicatorIdx: [],
    // pressureIdx: [],
},
{
    icon: "mdi-fish",
    title: "Fish passage remediation",
    actionIndicatorIdx: [5],
    pressureIdx: [5],
    longText: `Identifying barriers that block fish movements, then removing or modifying them will increase the movement and presence of indigenous (and maybe non-native) fish.`

    // actionIndicatorIdx: [],
    // pressureIdx: [],
},
{
    icon: "mdi-source-branch-remove",
    title: "Pest control",
    actionIndicatorIdx: [6],
    pressureIdx: [6],
    longText: `Removing unwanted weeds and predators or grazing animals will allow indigenous and other desired species to flourish and spread.`

    // actionIndicatorIdx: [],
    // pressureIdx: [],
},
];
export const pressures = [
    { title: "Vegetation clearance", pressureIndicatorIdx: [0, 1, 2] }, //1
    { title: "Increased temperature", pressureIndicatorIdx: [2, 3] }, //2
    { title: "Nutrients", pressureIndicatorIdx: [5] }, //3
    { title: "Erosion and sediment control", pressureIndicatorIdx: [4, 5] }, //4
    { title: "Altered hydrology", pressureIndicatorIdx: [6] }, //5
    { title: "River fragmentation", pressureIndicatorIdx: [7] }, //6
    { title: "Pest species", pressureIndicatorIdx: [8, 9] }, //7
];
export const outcomes = [{
    title: "Increased indigenous biodiversity",
    label: "Native Biodiversity ",
    icon: "mdi-family-tree",
    level: 'bronze',
    id: 1,
    longText: "Has the number of native or desired species increased? Are less common (and often more sensitive) native species reappearing?",
    subOutcomes: [
        {
            name: 'Terrestrial flora and fauna',
            id: 1,

            outcomes: [{ outcome: 'Terrestrial and wetland vascular vegetation', idx: '1' },
            { outcome: 'Terrestrial invertebrates', idx: '2' },
            { outcome: 'Herpetofauna', idx: '3' },
            { outcome: 'Bats', idx: '4' },
            { outcome: 'Birds', idx: '5' }]
        },
        {
            name: 'Instream flora and fauna',
            id: 2,
            outcomes: [{ outcome: 'Stream algae and periphyton', idx: '1' },
            { outcome: 'Stream plants macrophytes and bryophytes', idx: '2' },
            { outcome: 'Benthic macroinvertebrates', idx: '3' },
            { outcome: 'Stream mega-invertebrates', idx: '4' },
            { outcome: 'Fish', idx: '5' }]
        }]
},
{
    title: "Increased indigenous abundance/ dominance",
    icon: "mdi-upload-multiple",
    level: 'silver',
    id: 2,
    label: "Native Abundance & Dominance",
    longText: "Has the abundance of the native or desired species increased? Do they outnumber or provide more cover than the non-indigenous or undesired species? Have pest species decreased?",
    subOutcomes: [
        {
            name: 'Terrestrial flora and fauna',
            id: 1,
            outcomes: [{ outcome: 'Terrestrial and wetland vascular vegetation', idx: '1' },
            { outcome: 'Terrestrial invertebrates', idx: '2' },
            { outcome: 'Herpetofauna', idx: '3' },
            { outcome: 'Bats', idx: '4' },
            { outcome: 'Birds', idx: '5' }]
        },
        {
            name: 'Instream flora and fauna',
            id: 2,
            outcomes: [{ outcome: 'Stream algae and periphyton', idx: '1' },
            { outcome: 'Stream plants macrophytes and bryophytes', idx: '2' },
            { outcome: 'Benthic macroinvertebrates', idx: '3' },
            { outcome: 'Stream mega-invertebrates', idx: '4' },
            { outcome: 'Fish', idx: '5' }]
        }]
},
{
    title: "Improved ecological integrity",
    icon: "mdi-medal-outline",
    level: 'gold',
    id: 3,
    label: "Ecosystem Integrity",
    longText: "Is the restoration area functioning as a natural ecosystem, free from significant human impacts? This includes water quality and quantity, aquatic life, habitat, and ecological processes like nutrient uptake.",
    subOutcomes: [
        {
            name: 'Stream ecosystem health index',
            id: 3,
            outcomes: [{ outcome: 'Water quality', idx: '1' },
            { outcome: 'Water quantity', idx: '2' },
            { outcome: 'Physical habitat', idx: '3' },
            { outcome: 'Aquatic life', idx: '4' },
            { outcome: 'Ecological processes', idx: '5' }]
        },
        {
            name: 'Wetland condition index',
            id: 4,
            outcomes: [{ outcome: 'Hydrology', idx: '1' },
            { outcome: 'Physicochemical parameters', idx: '2' },
            { outcome: 'Ecosystem intactness', idx: '3' },
            { outcome: 'Browsing, predation and harvesting', idx: '4' },
            { outcome: 'Native plant dominance', idx: '5' }]
        }]
},
];
export const actionIndicators = [{
    title: "Length/ area fenced",
    page: 1,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "actionI",
},
{
    title: "Number/ density of seedlings",
    page: 2,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "actionI",
},
{
    title: "Bank length/ area planted",
    page: 3,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "actionI",
},
{
    title: "Number/ area of sediment retention in place",
    page: 4,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "actionI",
},
{
    title: "Number/ area of wetlands restored",
    page: 5,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "actionI",
},
{
    title: "Number of fish barriers fixed",
    page: 6,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "actionI",
},
{
    title: "Area of active pest management",
    page: 7,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "actionI",
},
];
export const pressureIndicators = [{
    title: "Terrestrial - vegetation class",
    page: 1,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "pressureI",
},
{
    title: "Terrestrial - habitat diversity",
    page: 2,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "pressureI",
},
{
    title: "Terrestrial - Canopy height & cover",
    page: 3,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "pressureI",
},
{
    title: "Instream - shade",
    page: 4,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "pressureI",
},
{
    title: "Instream - habitat diversity (flow, substrate, bank)",
    page: 5,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "pressureI",
},
{
    title: "Instream - water quality (nutrients, DO, temperature, clarity)",
    page: 6,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "pressureI",
},
{
    title: "Water quantity / level",
    page: 7,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "pressureI",
},
{
    title: "Habitat connectivity",
    page: 8,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "pressureI",
},
{
    title: "Pest plant abundance",
    page: 9,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "pressureI",
},
{
    title: "Pest animal catch / chew count",
    page: 10,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "pressureI",
},
];

export const outcomeIndicators = [{
    title: "Terrestrial flora and fauna",
    page: 1,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "outcomeI",
},
{
    title: "Instream flora and fauna biodiversity",
    page: 2,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "outcomeI",
},
{
    title: "Number / density of plants",
    page: 3,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "outcomeI",
},
{
    title: "Indigenous plant abnudance / dominance",
    page: 4,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "outcomeI",
},
{
    title: "Indigenous animal abundance / dominance",
    page: 5,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "outcomeI",
},
{
    title: "Stream ecosystem health",
    page: 6,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "outcomeI",
},
{
    title: "Wetland condition index",
    page: 7,
    url: "https://environment.govt.nz/what-government-is-doing/key-initiatives/jobs-for-nature/",
    type: "outcomeI",
},
];