<template>
  <div
    class="neutral"
    :style="'outline: solid;  outline-width:' + borderWidth + ';'"
  >
    <v-card class="actions-outcomes_card" tile elevation="0" :ripple="false">
      <v-img
        :src="
          require('../assets/images/cards-thumbnails/' +
            item.title.replace(/[^A-Z0-9]+/gi, '-').toLowerCase() +
            '.jpg')
        "
        aspect-ratio="1.7"
        cover

        @click="$emit('clicked')"
      />

      <v-card-title
        class="neutral justify-center py-2 px-0 white--text"
        @click="$emit('clicked')"
      >
        {{ item.title }}
      </v-card-title>
      <v-expansion-panels flat tile>
        <v-expansion-panel>
          <v-expansion-panel-header
            class="pt-6 pl-0 pb-0 mb-2"
            elevation="0"
            @click="showLongText = !showLongText"
          >
            <v-card-text class="py-0 pr-2 grey--text text--darken-2">
              {{
                showLongText
                  ? item.longText
                  : item.longText.slice(0, 100) + "..."
              }}
            </v-card-text>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-card-actions>
        <v-btn
          text
          block
          @click="$emit('clicked')"
          class="j4n-button-actions"
          :ripple="false"
          :class="selected && 'selected'"
        >
          <v-icon right v-if="selected"> mdi-check </v-icon>
          <span v-if="selected">Selected</span>
          <span v-else>Select</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    selected: Boolean,
  },
  name: "ActionCard",
  data: () => ({
    showLongText: false,
  }),
  computed: {
    borderWidth() {
      return this.selected ? "3px" : "0px";
    },
  },
};
</script>