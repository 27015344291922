<template>
  <div>
    <v-btn
      x-large
      :disabled="disabled"
      :loading="loading"
      @click="generateReport"
      class="j4n-button-generate-plan"
    >
      {{
        disabled ? "Select at least one action and outcome" : "Generate Plan"
      }}

      <template v-slot:loader>
        <span>{{ message }}</span>
      </template>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: Object,
      default: () => {
        return { actions: [], outcomes: [], project: "" };
      },
    },
  },
  name: "GenerateReportButton",
  methods: {
    generateReport() {
      let query = [
        ...this.selection.actions.map((a) => "a=" + a),
        ...this.selection.outcomes.map((o) => "oc=" + o),
        "p=" + this.selection.project,
      ].join("&");

      let url = process.env.VUE_APP_API + "?" + query;
      var vm = this;
      this.message = "Processing...";
      const onDownloadProgress = function (progressEvent) {
        const current = progressEvent.loaded;
        const total = progressEvent.target.response
          ? progressEvent.target.response.size
          : progressEvent.loaded;

        let percentCompleted = Math.floor((current / total) * 100);
        vm.message = "Loading " + percentCompleted + "%";

        vm.percentCompleted = percentCompleted;
        if (vm.percentCompleted == 100) {
          vm.loading = false;
          vm.percentCompleted = 0;
        }
      };

      let axiosConfig = { responseType: "blob", onDownloadProgress };
      this.loading = true;

      this.axios.get(url, axiosConfig).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "restoration_project_name.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
  },

  data: () => ({
    loading: false,
    message: "Generate Plan",
    percentCompleted: 0,
  }),
};
</script>
