<template>
  <v-card-text>
    <v-checkbox
      v-model="selectAll"
      :indeterminate="indeterminate"
      :ripple="false"
      hide-details
    >
      <span slot="label" class="mb-0 font-weight-bold">{{ group.name }}</span>
    </v-checkbox>
    <v-sheet class="ml-5">
      <v-checkbox
        :ripple="false"
        v-model="selectedSubOutcomes"
        v-for="v in group.outcomes"
        :key="group.name + ' ' + v.idx"
        :value="'OI' + group.id + '_C' + v.idx"
        :label="v.outcome"
        hide-details
      >
      </v-checkbox>
    </v-sheet>
  </v-card-text>
</template>

<script>
export default {
  props: {
    group: Object,
    triggerReset: Boolean,
  },
  components: {},
  mounted() {},
  name: "CheckBoxGroup",
  methods: {},
  data: () => ({
    selectAll: false,
    selectedSubOutcomes: [],
  }),
  watch: {
    triggerReset: function(){
      this.selectedSubOutcomes = []
    },
    selectedSubOutcomes: function (n) {
      if ([0, this.group.outcomes.length].includes(n.length)) {
        this.selectAll = n.length !== 0;
      }
      this.$emit("selection", this.selectedSubOutcomes);
    },
    selectAll: function (n) {
      if (n === true) {
        this.selectedSubOutcomes = this.group.outcomes.map(
          (g) => "OI" + this.group.id + "_C" + g.idx
        );
      } else {
        this.selectedSubOutcomes = [];
      }
    },
  },
  computed: {
    indeterminate: function () {
      return ![0, this.group.outcomes.length].includes(
        this.selectedSubOutcomes.length
      );
    },
  },
  
};
</script>
