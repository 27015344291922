<template>
  <v-container>
    <v-row>
      <!-- <v-row align="center" class="app-intro-text_wrapper"> -->
        <v-col cols="12">
          <v-card-text align="center">
            <h4 class="white--text" style="font-size: 50px">
              Building your monitoring guide is easy, start with these three
              steps:
            </h4>
          </v-card-text>
        </v-col>
      <!-- </v-row> -->

      <v-row class="app-intro-text_wrapper">
        <v-col cols="12" xs="12" sm="12" class="pa-0 mb-2">
          <v-img :src="arrows_steps" height="160" contain/>
        </v-col>
      </v-row>
      <v-row class="app-action-outcome_wrapper">
        <v-row
          justify="center"
          align="center"
          class="app-action-outcome-toggle_heading_wrapper"
        >
          <div style="align-text: center">
            <h6 align="center">1. Select Restoration Action(s)</h6>
          </div>
        </v-row>

        <v-row class="app-action-outcome-card_wrapper">
          <transition-group name="fade-in" mode="out-in" class="row">
            <template>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="4"
                v-for="(item, idx) in actions"
                :key="'ca' + item.title"
              >
                <action-card
                  :item="item"
                  :selected="selectedActionTitles.includes(item.title)"
                  @clicked="selectActionIndex(idx)"
                >
                </action-card>
              </v-col>
            </template>
          </transition-group>
        </v-row>

        <v-row
          justify="center"
          align="center"
          class="app-action-outcome-toggle_heading_wrapper"
        >
          <div style="align-text: center">
            <h6 style="margin-top: 50px; margin-bottom: 50px">
              2. Select Restoration Outcome
            </h6>
          </div>
        </v-row>
        <v-row class="app-action-outcome-card_wrapper">
          <transition-group name="fade-in" mode="out-in" class="row">
            <template>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="6"
                lg="4"
                v-for="(item, idx) in outcomes"
                :key="'caOut' + item.title + idx"
              >
                <!-- {{item.subOutcomes}} -->
                <outcome-card
                  :item="item"
                  :selected="selectedOutcomeId == item.level"
                  @clicked="
                    selectedOutcomeId =
                      selectedOutcomeId == item.level ? null : item.level
                  "
                  @updateOutcomes="setOutcomeCompomemts(item.level, $event)"
                />
              </v-col>
            </template>
          </transition-group>
        </v-row>

        <v-row
          justify="center"
          align="center"
          class="app-action-outcome-generate_wrapper"
        >
          <GenerateReportButton
            :selection="requestData"
            :disabled="buttonDisabled"
          />
        </v-row>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import logo from "../assets/JFN-English-Rev.svg";
import arrows_steps from "../assets/arrows_steps.svg";
// import arrow_start from "../assets/arrow_start.svg";
// import arrow_end from "../assets/arrow_end.svg";
import docLogo from "../assets/DOC_logo_horizontal_RGB_White_Text_1.svg";
import { actions, outcomes } from "../plugins/dataset";
import ActionCard from "./ActionCard.vue";
import OutcomeCard from "./OutcomeCard.vue";
import GenerateReportButton from "./GenerateReportButton.vue";

export default {
  components: { ActionCard, OutcomeCard, GenerateReportButton },
  name: "LandingPage",
  methods: {
    selectActionIndex(idx) {
      if (this.selectedActions.includes(idx)) {
        this.selectedActions = this.selectedActions.filter((e) => e != idx);
      } else {
        this.selectedActions.push(idx);
      }
    },
    setOutcomeCompomemts(level, e) {
      this.selectedOutcomeComponents[level] = e;
    },
  },
  data: () => ({
    panelsDefault: [],
    showLayout: false,
    logo,
    arrows_steps,
    // arrow_start,
    // arrow_end,
    docLogo,
    actions,
    outcomes,
    projectName: "",
    selectedActions: [],
    selectedOutcomeId: null,
    selectedOutcomeComponents: { bronze: [], silver: [], gold: [] },
  }),
  computed: {
    selectedActionTitles() {
      return this.selectedActions.map((idx) => actions[idx].title);
    },
    buttonDisabled() {
      return (
        this.requestData.actions.length == 0 ||
        this.requestData.outcomes.length == 0
      );
    },
    requestData() {
      var temp = {
        project: this.projectName,
      };
      temp["actions"] = this.selectedActions.map((i) => i + 1).sort();
      temp["outcomes"] =
        this.selectedOutcomeComponents[this.selectedOutcomeId] || [];
      return temp;
    },
  },
};
</script>